

.dropzone {
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    min-width: 70%;
    text-align: center;
    padding: 20px;
    border: 1px rgb(0, 0, 0) dashed;
}
.konvajs-content {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.dropzone-stage {
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    min-width: 70%;
    text-align: center;
    padding: 20px;
    border: 1px rgb(0, 0, 0) dashed;
}
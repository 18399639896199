.autocomplete-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;

  input.autocomplete-input {
    // Hide the arrow of the input when form field is valid
    &.is-valid + div.autocomplete-dropdown-toggle-btn {
      display: none;
    }
  }

  div.autocomplete-dropdown-toggle-btn {
    position: absolute;
    right: 1rem;
    font-size: 10px;
  }

  div.autocomplete-dropdown-options-wrapper {
    position: absolute;
    background: white;
    max-height: 20vh;
    overflow-y: auto;

    &.open {
      border: 1px solid black;
      z-index: 9999;
    }

    > .option {
      padding: 0 0.75rem;

      &:hover {
        cursor: pointer;
        background: var(--wizard-step-background);
      }
    }
  }
}

.language-select {
  width: 120px;

  .autocomplete-dropdown-toggle-btn {
    display: flex;
    align-items: center;
    background: none;
    border: 1px solid lightgray;
    border-radius: 5px;
  }

  .autocomplete-dropdown-options-wrapper.open {
    border: 1px solid lightgray !important;
  }
}
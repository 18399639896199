// Import Bootstrap and its default variables
@import "bootstrap/scss/bootstrap.scss";

:root {
  --amplify-primary-color: var(--bs-primary);
  --amplify-primary-tint: var(--bs-primary);
  --amplify-primary-shade: var(--bs-primary);
  --container-height: 89vh;

  --accent-color: #ff562b;
  /* TecAlliance Portland */
  --primary-text-color: #272838;
  /* TecAlliance Raisin */
  --wizard-step-background: #eaeaea;
  // --footer-color: #36c275;
  --footer-color: #272838;

  /* TecRMI Brand Colour */
  --brand-color: #3ddb84;
  /* TecAlliance Emerald */
  --tint-blue: #6cb6f2;

  // Override Bootstrap variables with TecRMI color schema
  --bs-primary: #258550;
  --bs-secondary: #4c4b63;
  --bs-info: #ff562b;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-success: #28a745;

  --bs-brand: var(--brand-color);

  --header-z-index: 98;
}

#tecalliance-theme {
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  /* Custom accent color for checkboxes and radio buttons */
  input[type="checkbox"],
  input[type="radio"] {
    accent-color: var(--accent-color);
  }

  .form-check-input:checked {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
  }

  .form-label {
    margin-top: 0.5rem;
  }

  .main {
    border: 1px solid lightgray;
    padding: 2rem;
  }

  .login-container {

    // 100vh - header - footer
    // background: url("./assets/car_parts.jpg");
    @media (orientation:landscape) {
      background: linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url("./assets/car_parts_landscape.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-attachment: fixed;
      }

    @media (orientation:portrait) {
      background: linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url("./assets/car_parts_portrait.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-attachment: fixed;
      }

    max-height: calc(100% - 48.72px - 35.27px);
    min-height: calc(100vh - 48.72px - 35.27px);
    ;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;


    // @media (orientation:portrait) {
    //   background: linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url("./assets/car_parts.jpg");
    //   -webkit-transform: rotate(90deg);
    //   -moz-transform: rotate(90deg);
    //   background-repeat: no-repeat;
    //   background-size: auto;
    //   background-position: center;
    //   background-attachment: fixed;
    //   }

    .login {
      padding: 35px 40px;
      border-radius: 0.5rem;
      min-width: 20rem;

      @media (min-width: 672px) {
        width: 28.75rem;
      }

      .login-header {
        margin: 1rem 0 1.5rem 0;

        &-text {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0.75rem;
        }
      }

      .login-main {
        .show-password-btn {
          width: 20px;
          position: relative;
          bottom: 1.875em;
          right: 0.5em;

          color: #6c757d;

          margin-left: auto;

          cursor: pointer;
        }
      }

      .login-footer {
        margin-top: 2rem;

        .sign-in-button {
          text-transform: uppercase;
          padding: 0.5em;
          min-width: 153px;
          width: 100%;
        }
      }

      .form-label {
        font-size: 14px;
      }
    }

    .register-text {
      font-size: 13px;

      >a {
        padding-left: 0.25rem;
        text-decoration: none;
        color: var(--bs-primary);

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    background-color: var(--footer-color);
    color: white;
    padding: 0px 30px 0px 30px;
    width: 100%;

    .links {
      float: left;

      a {
        color: white;
        padding: 5px 5px 5px 10px;
        align-items: center;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .logo {
      float: right;
    }
  }

  .form-group {
    margin-bottom: 1rem;

    textarea.form-control {
      margin-left: 0.5rem;
      width: calc(100% - 0.5rem);
    }
  }

  .badge.env-badge {
    margin-left: 2rem;
    background-color: #dc3545 !important;
    font-size: 14px;
    color: white;
    border-radius: 0.5rem;
  }

  @import "./styles/Button.scss";
}
/* MetadataSelector.scss */

.metadata-selector {
    .metadata-group {
      margin-bottom: 20px;
    }
  
    .metadata-badge-list {
      display: flex;
      flex-wrap: wrap;
      max-width: 800px;
      margin: 0 auto;
    }
  
    .badge-clickable {
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      user-select: none;
    }
  
    .badge-primary {
      background-color: #007bff;
    }
  
    .badge-secondary {
      background-color: #6c757d;
    }
  
    .badge-primary.selected {
      background-color: #0056b3;
    }
  
    .card-title {
      font-size: 1em; /* Adjust the font size as needed */
    }
  }
  
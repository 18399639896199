// $primary-color: #3ddb84;
$primary-color: #272838;
@mixin circle-button($size: 40px) {
  width: $size;
  height: $size;
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: whitesmoke;
}

.popover {
  width: 300px;
  max-width: 300px !important;

  .popover-arrow {
    display: none !important;
  }

  .popover-header {
    padding: 2vh;
    background-color: #e9ecef;
  }
}

#button-dropdown {
  @include circle-button(40px);
}

.user-info-items {
  display: flex;
  align-items: center;

  .user-info-items__circle {
    @include circle-button(45px);
    margin-right: 2vh;
    font-size: 1rem;
  }

  .user-info-item__username {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .user-info-item__useremail {
    font-size: 14px;
  }
}

.dropdown-item-link {
  padding: 0.25rem 0;
  width: fit-content;
  display: flex;
}

.dropdown-item-nav-link {
  width: fit-content;
  margin-left: auto;
}

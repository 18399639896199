// Override bootstrap buttons

// Accent/action buttons behave diffrently
$button-types: primary, secondary, success, link, brand;

@each $type in $button-types {
  .btn-#{$type} {
    background-color: var(--bs-#{$type});
    border-color: var(--bs-#{$type});

    &:hover {
      filter: brightness(.85);
      background-color: var(--bs-#{$type});
      border-color: var(--bs-#{$type});
    }
  }

  .btn-outline-#{$type} {
    background-color: #fff;
    border-color: var(--bs-#{$type});
    color: var(--bs-#{$type});

    &:hover {
      background-color: var(--bs-#{$type});
      color: #fff;
    }
  }
}

.action-btn {
  outline: none;
  border: 0px;
  box-sizing: border-box;
  line-height: normal;
  color: gray;
  background-color: transparent !important;
  padding: 0;

  &:hover, &:focus {
    outline: none !important;
    border: 0px !important;
    color: var(--accent-color) !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.btn-accent {
  color: var(--accent-color);
  background-color: #fff;
  border-color: var(--accent-color);

  &:hover, &:active, &-selected  {
    color: #fff;
    background-color: var(--accent-color);
    border-color: var(--accent-color);
  }

  &:focus, &-selected:focus {
    box-shadow: 0 0 0 0.2rem var(--accent-color);
  }
}

.btn-link {
  font-weight: bold;
  color: var(--accent-color);
  text-decoration: none;
}

.accordion-button.collapsed.btn.btn-link {
  &::after {
    content: none;
  }

  > svg {
    margin-left: .5rem;
  }
}